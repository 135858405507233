export const listbookings = {
  text1: "Welcome!",
  text2: "Check-in list, manage or send a link for your guests' registration.",
  text3: "Check-in list",
  text4: "Export",
  text5: "New check-in",
  text6: "All",
  text7: "Pending",
  text8: "Complete",
  text9: "Today",
  text10: "Phone",
  text11: "Name",
  text12: "ID Number",
  text13: "Country",
  text14: "Check-In",
  text15: "Keys",
  text16: "Delivered",
  text17: "Waiting",
  text18: "View full information",
  text19: "Signed document",
  text20: "All",
  text21: "There is an error in the delivery confirmation process",
  text22: "Check-in information",
  text23: "Data collected at guest check-in",
  text24: "First name",
  text25: "Last name",
  text26: "Phone",
  text27: "Lodging date",
  text28: "Document type",
  text29: "Passport",
  text30: "National identity document",
  text31: "Document number",
  text32: "Document expiration",
  text33: "Nationality",
  text34: "Date of birth",
  text35: "Gender",
  text36: "Female",
  text37: "Male",
  text38: "Write here your ...",
  text39:
    "Hello [guest_name],\nWelcome to HOTEL BARCELO MALAGA!\n\nThank you very much for staying with us, we inform you that check-out time is until 10.00 AM.\n\nAdditionally, we inform you of the access details to our Wifi network:\n\nNetwork: HOTEL-BARCELO\nPassword: xkamakj1a0\n\n\nWe hope your stay is as pleasant as possible.\n\nWe are at your disposal at the reception 24 hours.\n\nBest regards!",
  text40: "The name of your accommodation is required.",
  text41: "The accommodation policies are required.",
  text42: "A welcome message must be included.",
  text43: "Changes saved successfully",
  text44: "Request error",
  text45: "Successfully registered.",
  text46: "😃 Hello!! ",
  text47:
    " You have successfully completed the process of creating your first accommodation, now you can share your QR for users to ",
  text48: "check-in",
  text49: "automated 👍",
  text50: "You can edit the fields entered previously from the menu",
  text51: "Template settings.",
  text52: "Download",
  text53: "Continue",
  text54: "Check-in information.",
  text55: "Check-in data",
  text56: "Request date:",
  text57: " at",
  text58: "Data collected at guest check-in",
  text59: "Name",
  text60: "Last name",
  text61: "Phone",
  text62: "Lodging date",
  text63: "Document type",
  text64: "Passport",
  text65: "National identity document",
  text66: "Document number",
  text67: "Additional information",
  text68: "Document expiration",
  text69: "Nationality",
  text70: "Date of birth",
  text71: "Gender",
  text72: "Female",
  text73: "Male",
  text74: "Close",
  text75: "New Check-In",
  text76:
    "You can send the link or create a manual check-in in the following options.",
  text77: "Check-In",
  text78: "Manual check-in from the admin panel.",
  text79: "Send Check-In",
  text80: "Reservation link to a guest.",
  text81: "Loading, please wait...",
  text82: "Years",
  text83: "Days",

  // Modal exportar
  text84: `EXPORT`,
  text85: `Export reports in WACheckin`,
  text86: `Download Check-in Report`,
  text87: `Download the check-ins made by your guests and get a
    detailed report with information such as check-in date, among
    other data.`,
  text88: `Download migration report`,
  text89: `If automatic synchronization with the migration entity fails
    you can download this report and manually enter your guest's
    data.`,

  text90: `Keys Delivered`,
  text91: `Authorities`,
  text92: `Download Police Report`,
  text93: `Delete check-in`,
  text94: `There are no guests registered in your accommodation yet`,
  text95: `Registered guests will appear here`,
  text96: `EXPORT`,
  text97: `EXPORT`,
  text98: `EXPORT`,

  // Corrections

  p1: `Send`,
  p2: `Check-in Link`,
  p3: `Scan`,
  p4: `The guest will sign on this device`,
  p5: `Scanned`,
  p6: `Manual`,
  p7: `Manual operator`,
  p8: `check-ins`,
  p9: `Use the manual check-in option to register your guests directly from the reception`,
  p10: `Manual check-in`,
  p11: `Send the online check-in link to your guests via WhatsApp or email to facilitate their registration before their arrival`,
  p12: `Send online check-in`,
  p13: `Unspecified`,
  p14: `Delete check-in`,
  p15: `Are you sure you want to delete the check-in? You will not be able to recover the deleted data`,
  p16: `Delete`,
  p17: `Cancel`,
  p18: `Unlock the full potential with our premium features`,
  p19: `Auto check-in via WhatsApp`,
  p20: `Travelers' doc. submission`,
  p21: `Identity verification`,
  p22: `Automatic rating`,
  p23: `Cross-selling`,
  p24: `Guest key management`,
  p25: `Guest billing`,
  p26: `Don't miss out on the benefits!`,
  p27: `Upgrade to Premium`,

  p28: `Concept`,
  p29: `Additional charge`,
  p30: `Status`,
  p31: `Paid`,
  p32: `Pending`,
  p33: `Refunded`,
  p34: `Refund charge`,
  p35: `Refund`,
  p36: `Are you sure you want to refund the charge to the guest?`,
  p37: `Identity Document`,
  p38: `Passport`,
  p39:`FILTER`,
  p40:`Check-ins completed`,
  p41:`Check-ins in progress`,
  p42: `You have no check-ins in progress`,
  p43: `Check-in Information`,
  p44: `Associated Check-ins`,
  p45: `No associated check-ins.`,
  p46: `More information:`,
  p47: `Check-in date:`,
  p48: `Check-out date:`,

  p49:`Guest`,
  p50:`Guests`,


};
