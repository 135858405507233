<template>
  <div class="containerSetProperty">
    <img
     v-animate-css="'slideInDown'"
      src="/img/1x/logo1.png"
      alt="logoWacheckin"
      class="logoWacheckin"
      style="
       
      "
    />

    <div class="containerBody container d-flex flex-column" v-animate-css="'slideInUp'">
      <div>
        <div class="titlePageSelectProperty">¿Qué alojamiento quieres gestionar?</div>
      </div>

      <div class="row" style="justify-content: center;"> 
        <div
          class="conteinerItemProperty col-12 col-lg-3 itemProperty"
          v-for="(item, index) in properties.slice(0, 8)"
          :key="index"
          @click.prevent="setProperty(item.id)"
          style="margin-bottom: 65px;"
        >
          <div
            v-if="item.logo"
            class="imageAccommodation"
            :style="{
              backgroundImage: 'url(' + $base.server + item.logo + ')',
            }"
          ></div>
          <div
            v-if="!item.logo"
            class="letterAccommodation d-flex"
          >{{ item.nombre.substring(0,1) }}</div>

          <div class="titleAccommodation">{{ item.nombre }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "SelectProperty",
  mounted() {
    this.getProperties();
  },
  computed: {
    ...mapState("property", ["property", "properties"]),
  },
  methods: {
    ...mapActions("property", ["getProperties"]),
    setProperty(propiedad) {
            localStorage.setItem("propiedadActiva", propiedad);
            this.$store.state.propiedadActiva = propiedad;
            localStorage.setItem("selectPropertyView", propiedad);

            // this.$store.state.selectPropertyView = propiedad;

            location.reload();

           
    },
  },
};
</script>
<style lang="scss" scoped>
@media(min-width:768px){
    .row {
        width: 65%;
    }
    .logoWacheckin {
        width: 160px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 120px;
    }
}

@media(max-width: 768px){
    .logoWacheckin {
        width: 160px;
        margin: auto;
        margin-top: 50px;
    }

    .containerBody {
        justify-content: initial !important;
        align-items: center !important;
        height: 100% !important;
        margin-top: 33px !important;
        padding: 22px !important;
        overflow: scroll;
        padding-bottom: 150px !important;
    }
    .imageAccommodation {
        background-repeat: no-repeat;
        box-shadow: 0px 0px 8px 2px #0000001c;
        border-radius: 5px;
        padding: 60px !important;
        background-color: #fff;
        background-position: center;
        height: 100%;
        width: 100%;
        cursor: pointer;
        background-size: 58% !important;
    }

    .letterAccommodation {
        font-size: 54px !important;
    }
}
.containerSetProperty {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1200;

  .containerBody {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .titlePageSelectProperty {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .titleAccommodation {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    color:#333;
  }

  

  .imageAccommodation {
    height: 100px;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 8px 2px #0000001c;
    border-radius: 5px;
    padding: 20px;
    background-color: #fff;
    background-position: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 83%;
    min-height: 76px;
  }

  .letterAccommodation {
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 8px 2px #0000001c;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
</style>
