<template>
  <div
    class="submenu"
    :class="{ inactive: validateSubMneu() }"
    :style="$store.state.demo ? { top: '91px !important' } : {}"
  >
    <div class="container-fluid">
      <div class="row justify-content-center" style="background:white">
        <div class="col-10" style="padding-left: 0; padding-right: 0">
          <div class="links d-flex justify-content-between">
            <ul class="me-auto p-2 bd-highlight d-flex flex-row base-menu">
              <router-link
                :to="{ name: 'Bookings' }"
                tag="li"
                class="d-flex flex-row link-menu"
                style="position: relative; align-items: center"
                :class="{ active: $route.name == 'Bookings' }"
              >
                <i
                  
                  class="fa-duotone fa-circle-question"
                  v-b-tooltip.hover.top
                  :title="$t('empresa.maqueta.p14')"
                ></i>
                {{ $t("empresa.maqueta.text38") }}
                <i class="fa-duotone fa-shield-check ml-2"></i>
              </router-link>
              <router-link
                :to="{ name: 'Reservas' }"
                tag="li"
                class="d-flex flex-row link-menu"
                style="position: relative; align-items: center"
                :class="{
                  active: $route.name == 'Reservas' || $route.name == 'ReservasID',
                }"
              >
                <i
                  
                  class="fa-duotone fa-circle-question"
                  v-b-tooltip.hover.top
                  :title="$t('empresa.maqueta.p15')"
                ></i>
                {{ $t("empresa.maqueta.p1") }}
                <i class="fa-duotone fa-calendar-days ml-2"></i>
              </router-link>

              <router-link
                :to="{ name: 'Servicios' }"
                tag="li"
                class="d-flex flex-row link-menu"
                :class="{ active: $route.name == 'Servicios' }"
                style="position: relative; align-items: center"
              >
                <i
                  
                  class="fa-duotone fa-circle-question"
                  v-b-tooltip.hover.top
                  :title="$t('empresa.maqueta.p16')"
                ></i>
                {{ $t("empresa.maqueta.text39") }}
                <i class="fa-duotone fa-store ml-2"></i>
              </router-link>


              <router-link
                :to="{ name: 'indexWhatsappCommunication' }"
                tag="li"
                class="d-flex flex-row link-menu"
                :class="{ active: $route.name == 'indexWhatsappCommunication' }"
                style="position: relative; align-items: center"
              >
              
                <i
                  
                  class="fa-duotone fa-circle-question"
                  v-b-tooltip.hover.top
                  :title="$t('empresa.maqueta.p17')"
                ></i>
                {{ $t("empresa.maqueta.p17") }}
                <i class="fa-duotone fa-message-captions ml-2"></i>
              </router-link>


            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    // this.listarUser();
    console.log("subnav components");
  },
  data() {
    return {
      enviando: false,
      user: null,
    };
  },
  methods: {
    validateSubMneu() {
      if (
        this.$route.name != "Bookings" &&
        this.$route.name != "Reservas" &&
        this.$route.name != "Servicios" &&
        this.$route.name != "indexWhatsappCommunication"
      ) {
        return true;
      }
    },
    calcularDiasRestantes(fecha) {
      console.log(fecha, " -- - -");
      const fechaRegistro = this.moment(fecha);
      const ahora = this.moment();
      const diasTranscurridos = ahora.diff(fechaRegistro, "days");

      console.log(ahora);

      const diasRestantes = 14 - diasTranscurridos;

      return diasRestantes;
    },
    listarUser() {
      let url = this.$base.dominio + "/user/data";
      axios.get(url).then((res) => {
        this.user = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-circle-question {
  position: absolute;
                    top: -8px;
                    color: #7c7c7c;
                    z-index: 2000;
                    right: -15px;
}
.links {
  background: #ffffff;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 64px 2px #f0f0f2;
    margin-top: 21px;
}
.inactive {
  display: none !important;
}
.base-menu {
  align-items: center;
  // height: 41px;
  // padding-left: 0 !important;

  margin-bottom: 0px;
  padding-left: 10px !important;
}
.vx-navbar-wrapper {
  z-index: 41001;
  width: 100%;
  height: 79px !important;
  top: 0;
}

.link-menu {
  margin-right: 27px;
  border-radius: 10px;
  padding: 2px 10px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  color: #333;

  &:hover {
    transition: 0.3s;
    background: #ebeef1;
    color: #333 !important;
  }
}

.link-menu.active {
  background: #5e72e4 !important;
  color: #fff !important;
}

.link-menu-dos {
  margin-left: 27px;
  border-radius: 40px;
  padding: 2px 10px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  color: #333;

  &:hover {
    transition: 0.3s;
    background: #ebeef1;
    color: #333 !important;
  }
}

.link-menu-dos.active {
  background: #5e72e4 !important;
  color: #fff !important;
}

.submenu {
  position: fixed;
  top: 67px;
  background: white;
  left: 0;
  right: 0;
  height: 41px;
  z-index: 101;
  // border-bottom: 1px solid #eee;//
}
//  WEB
.vx-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

.navbar-floating .vx-navbar {
  border-radius: 0.5rem;
  padding: 0.8rem 8rem !important;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px !important;
}
</style>
