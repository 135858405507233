import stateSubscription from './stateSubscription';
import mutations from './mutations';
import actions from './actions';

export default {
  state: stateSubscription,
  mutations,
  actions,
  namespaced: true
};
