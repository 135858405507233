<template>
  <div
    class="the-navbar__user-meta flex items-center"
    style="margin-left: 10px"
  >
    <div
      class="dropdown"
      ref="dropdown"
    >
      <div
        class="dropdown-toggle parent-dropdown cursor-pointer;"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <div
          @click.prevent="activeNav()"
          class="selectCuenta d-flex"
          style="align-items: center; cursor: pointer"
        >
          <div
            class="favicion"
            style="margin-right: 12px"
          >
            <div
              v-if="propiedad_select && propiedad_select.logo"
              class="imageFavicon"
              :style="{
                'background-image': `url(${
                  $base.server + propiedad_select?.logo
                })`,
              }"
            ></div>
            <div
              v-if="propiedad_select && !propiedad_select.logo"
              class="imageLetter d-flex"
            >
              {{
                propiedad_select?.nombre
                  ? propiedad_select?.nombre.substring(0, 1)
                  : null
              }}
            </div>
          </div>
          <div
            class="nameCount"
            style="margin-right: 12px"
          >
            {{ truncatedName(propiedad_select?.nombre) }}
          </div>
          <div class="iconSelect">
            <i class="fa-regular fa-angle-down"></i>
          </div>
        </div>

        <nav-movil
          class="xs"
          v-if="navM == true"
        />
        <div
          class="lg dropdown-menu menuPopap user dropdown-menu-right dropdown-menu-arrow"
        >
          <div
            class="d-flex flex-column"
            style="padding: 2px 15px"
          >
            <div class="titleProperty">
              {{ propiedad_select?.nombre }}
            </div>

            <div style="text-align: center">
              <strong style="font-size: 14px; font-weight: 600">{{
                $store.state.userRoot.name
              }}</strong>
            </div>
          </div>

          <a
            href="#"
            class="noV dropdown-item d-flex"
            style="align-items: center; position: relative"
            @click.prevent="irRuta('configAccount')"
          >
            <i
              class="fa-duotone fa-circle-question"
              v-b-tooltip.hover.top
              :title="$t('lg.nav.p1')"
            ></i>

            <i class="fa-regular fa-user-circle mr-2"></i>
            {{ $t("lg.nav.p2") }}
          </a>

          <a
            href="#"
            class="noV dropdown-item d-flex"
            style="align-items: center; position: relative"
            @click.prevent="handleOptionClick('data-basic-accommodation')"
          >
            <i
              class="fa-duotone fa-circle-question"
              v-b-tooltip.hover.top
              :title="$t('lg.nav.p3')"
            ></i>

            <i class="fa-solid fa-folder-gear mr-2"></i>

            {{ $t("lg.nav.p4") }}
          </a>

          <a
            href="#"
            class="noV dropdown-item d-flex"
            style="align-items: center; position: relative"
            @click.prevent="irRuta('Ofertas')"
          >
            <i
              class="fa-duotone fa-circle-question"
              v-b-tooltip.hover.top
              :title="$t('lg.nav.p5')"
            ></i>

            <i class="fa-regular fa-store mr-2"></i>

            {{ $t("lg.nav.p6") }}
          </a>

          <a
            class="dropdown-item d-flex"
            style="align-items: center"
            href="#"
            @click.prevent="logout"
          >
            <i class="fa-regular fa-arrow-right-from-bracket mr-2"></i>
            {{ $t("empresa.maqueta.text13") }}
          </a>

          <hr />
          <div class="divAccommodation">
            <div
              class="acoommodationSelect d-flex flex-row"
              v-for="(item, index) in properties"
              :key="index"
              @click.prevent="handlePropertySelect(item.id)"
            >
              <div class="imagenPropiedad">
                <div
                  v-if="item.logo"
                  class="imageFavicon"
                  style="
                    height: 42px;
                    width: 42px;
                    border-radius: 50px;
                    border: 1px solid #eee;
                    background-repeat: no-repeat;
                  "
                  :style="{
                    'background-image': `url(${$base.server + item.logo})`,
                  }"
                ></div>
                <div
                  v-if="!item.logo"
                  class="imageLetterAccommodation d-flex"
                >
                  {{ item.nombre.substring(0, 1) }}
                </div>
              </div>
              <div class="nameAccommodation">
                {{ item.nombre }}
              </div>
            </div>
            <div
              class="noV acoommodationSelect d-flex flex-row"
              style="align-items: center; cursor: pointer"
              @click.prevent="validateAccommodation(), closeDropdown()"
            >
              <div class="imagenPropiedad">
                <div class="imageFaviconAccommodation d-flex">
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
              <div
                class="nameAccommodation"
                style="font-weight: 700; margin-left: 13px"
              >
                {{ $t("lg.nav.p7") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-limit-exceeded />
  </div>
</template>

<script>
import navMovil from "@/views/Template/Maqueta/navMovil.vue";
import { mapActions, mapState } from "vuex";
import ModalLimitExceeded from "./modalLimitExceeded.vue";
export default {
  components: { navMovil, ModalLimitExceeded },
  name: "dropdownAccommodations",
  computed: {
    ...mapState("property", ["property", "properties"]),
    ...mapState("user", ["user"]),
  },
  mounted() {
    this.getProperties();
    this.propiedad = this.$store.state.propiedadActiva;
    this.propiedad_select = this.property;

    $(".dropdown-menu").on("click", function (e) {
      e.stopPropagation();
    });
  },
  watch: {
    property(val) {
      this.propiedad_select = val;
      this.$store.state.propiedadActiva = val.id;
      console.log(
        "Watch property change nav - change property",
        this.$store.state.propiedadActiva
      );
      const storeLocalProperty = localStorage.getItem("propiedadActiva");
      if (!storeLocalProperty) {
        localStorage.setItem("propiedadActiva", val.id);
      }
    },
  },
  data() {
    return {
      propiedad_select: null,
      propiedad: null,
      navM: false,
      visibleModalBasic: false,
    };
  },
  methods: {
    ...mapActions("property", ["getProperties", "getProperty"]),
    closeDropdown(event) {
      const dropdownMenu = $(event.target).closest(".dropdown");
      dropdownMenu.find(".dropdown-toggle").dropdown("hide");
    },
    validateAccommodation() {
      if (this.properties.length >= this.$store.state.userRoot.cantidad) {
        // alert('Has superado el limite de propiedades permitidas');
        this.visibleModalBasic = true;
        return false;
      } else {
        this.irRuta("createPropiedad");
      }
    },
    truncatedName(name) {
      if (!name) return "";
      return name.length > 15 ? name.substring(0, 15) + "..." : name;
    },
    activeNav() {
      console.log("-- activeNav - - ");
      this.navM = true;
    },
    closeDropdown() {
      const dropdownMenu = this.$refs.dropdown.querySelector(".dropdown-menu");
      if (dropdownMenu && dropdownMenu.classList.contains("show")) {
        dropdownMenu.classList.remove("show");
      }
    },

    handleOptionClick(route) {
      this.cambiarRuta(route);
      this.closeDropdown();
    },
    handlePropertySelect(id) {
      this.ChangePropiedad(id);
      this.closeDropdown();
    },

    cambiarRuta(ruta) {
      this.$router.push(
        "/business/properties/edition/" +
          this.$store.state.propiedadActiva +
          "/" +
          ruta
      );
    },
    irRuta(ruta) {
      if (ruta != this.$route.name) {
        this.$router.push({ name: ruta });
      }
      this.closeDropdown();
    },
    irRutaWaba() {
      if (this.user && this.user.waba_default != "EMPRESA") {
        $("#modalWhatsapp").modal("show");
      } else {
        this.$router.push({ name: "wabaIntegration" });
      }
    },

    irRutaSub() {
      this.$router.push({ name: "pageSubscription" });
    },
    activarModalIdiomas() {
      this.$funtion.modalIdiomas();
    },

    ChangePropiedad(propiedad) {
      localStorage.setItem("propiedadActiva", propiedad);
      this.$store.state.propiedadActiva = propiedad;
      
      if (this.$route.name != "Bookings") {
        this.$router.push({ name: "Bookings" });
      }

      setTimeout(() => {
        location.reload();
      },300)

     
    },

    async fetchData() {
      try {
        const datos = await this.$funtion.propertySelect();
        this.propiedad_select = datos;
        this.$store.state.propiedadActiva = datos.id;
      } catch (error) {
        console.log(error);
      }
    },

    async getPropiedadActiva() {
      var result = await this.$funtion.propertySelect();
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error(this.$t("empresa.maqueta.text14"));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("./dropdownAccommodation.scss");
</style>
