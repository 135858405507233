<template>
  <div class="Ini-auth" style="background: transparent; text-align: left">
    <nav
      class="navbar xs navbar-light bg-light"
      style="
        height: 69px;
        background: rgb(0 0 0 / 0%) !important;
        border-bottom: 1px solid rgb(0 0 0 / 0%);
      "
    >
      <div class="container">
        <div class="col-6 lg">
          <img
            src="/img/1x/Logo_blanco.png"
            class="img-fluid"
            style="width: 129px"
          />
        </div>
        <div class="col-6 xs">
          <img
            v-animate-css="'slideInDown'"
            src="/img/1x/logo1.png"
            class="img-fluid"
            style="width: 129px; position: absolute; left: 2px"
          />
        </div>
        <div class="col-6"></div>
      </div>
    </nav>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  mounted() {
  // Verificar si el usuario está logueado, pero omitir la redirección en la ruta de cambio de contraseña
  if (this.$store.state.userRoot) {
    // Si el usuario está en una ruta de cambio de contraseña, no redirigir
    if (this.$route.name !== 'confirmacionReset') {
      if (this.$store.state.userRoot.type == "admin") {
        this.$router.push({ name: "HomeManager" });
      }
      if (this.$store.state.userRoot.type == "empresa") {
        this.$router.push({ name: "Bookings" });
      }
    }
  } else {
    localStorage.clear();
  }
}
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  width: 100%;
  height: 47px;
  bottom: 0;
  z-index: 1;
  background: #00000057;
  align-items: center;

  ul li {
    margin-left: 30px;
    font-size: 14px;
    color: white;
    font-weight: 600;
  }
}
</style>
