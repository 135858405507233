<template>
  <div
    v-if="
      user && 
      demo
    "
    class="alertDemo d-flex"
    v-animate-css="'slideInDown'"
    style=""
  >
    <span style=""
      ><template v-if="isOnTrial > 0">
        <strong style="font-size: 15px; color: #000">{{
          $t("empresa.home.p1")
        }}</strong>
        <strong style="font-size: 15px; color: #fff; text-transform: uppercase">
          {{ $t("empresa.home.p2") }}
        </strong>

        <strong
          style="font-size: 15px; color: #fff; text-transform: uppercase"
          >{{isOnTrial}}</strong
        >
        <strong style="font-size: 15px; color: #fff; text-transform: uppercase">
          {{ $t("empresa.home.p3") }}
        </strong>

        <button
          class="btn btn-success"
          style="
            font-size: 15px;
            color: rgb(255, 255, 255);
            cursor: pointer;
            padding: 0;
            padding-inline: 14px;
            margin-left: 10px;
            border-radius: 10px;
            margin-bottom: 0;
          "
          :style="$isMobile?{'margin-top': '3px'}: {}"
          @click.prevent="goRoute()"
        >
          {{ $t("empresa.home.p5") }}
        </button>
      </template>
      <template
        v-else-if="isOnTrial <= 0"
      >
        <strong style="font-size: 15px; color: #000">{{
          $t("empresa.home.p6")
        }}</strong>
        <button
          class="btn btn-success"
          style="
            font-size: 15px;
            color: rgb(255, 255, 255);
            cursor: pointer;
            padding: 0;
            padding-inline: 14px;
            margin-left: 10px;
            border-radius: 10px;
            margin-bottom: 0;
          "
          :style="$isMobile?{'margin-top': '3px'}: {}"
          @click.prevent="goRoute()"
        >
          {{ $t("empresa.home.p5") }}
        </button>
      </template></span
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "messageAccountDemo",
  data() {
    return {
      isOnTrial: null, // Almacenamos los días de prueba reactivamente
    };
  },
  computed: {
    ...mapState(["demo"]),
    ...mapState("property", ["property"]),
    user() {
      return this.$store.state.userRoot;
    },
  },
  watch: {
    user: {
      immediate: true, // Ejecuta al montar el componente
      handler(newVal) {
        if (newVal && newVal.servicios) {
          this.updateTrialDays();
        }
      },
    },
  },
  methods: {
    updateTrialDays() {
      if (!this.user || !this.user.servicios || !this.user.servicios.demo_expiration_date) {
        this.isOnTrial = 0;
        return;
      }

      const expirationDate = moment(this.user.servicios.demo_expiration_date);
      if (!expirationDate.isValid()) {
        this.isOnTrial = 0;
        return;
      }

      this.isOnTrial = expirationDate.diff(moment(), "days");
    },
    removeMessage() {
      this.$store.state.demo = false;
    },
    goRoute() {
      if (this.$route.name != "pageSubscription") {
        this.$router.push({ name: "pageSubscription" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media(max-width:768px){ 
  .alertDemo {
    position: relative;
    background: #e4c43c;
    color: #dedede;
    padding-top: 6px;
    padding-right: 6px;
    padding-bottom: 6px;
    top: -60px;
    left: 0px;
    position: absolute;
    right: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 64px;
}
}
@media(min-width: 768px){  //escritorio
  .alertDemo {
  position: relative;
  background: #e4c43c;
  color: #dedede;
  padding-top: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  padding-left: 121px !important;
  top: -36px;
  left: 0px;
  position: absolute;
  right: 0px;
  height: 35px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
}
</style>