
import  axios  from 'axios';
import { dominio } from '../../config/base';

const url = dominio;

export default {

    async retrivePricing({commit}){
        try{
            const response = await  axios.get(url + '/retrive-pricing');
            commit('setPricing', response);
            return response.data;
        }catch(error){
            console.log(error);
        }
    },

}